<template>
  <section>
    <div class="content-header">
      <h2>A flash of resilience </h2>
    </div>
    <div class="content-wrapper">
      <h4>Meditation</h4>
      <p>Consider incorporating mindful breathing at the start, end, or in the middle of your lecture to model stress management and practice alongside your students.</p>
      <p>These <a href="https://www.ryerson.ca/content/dam/thriveru/resources/SAThriveRU-Cards-web.pdf" target="_blank">Resilience Flash Cards</a> can help students address specific problems, situations or areas for development. </p>
      <p>Each card presents a situation and offers strategies to address it, including resources and tried-and-true strategies. </p>
      <p>For example, one flash card invites students to practice “savouring”. Savouring is taking the time to use all your senses to recall a past pleasant experience, to fully engage in present joys and to imagine a positive future event. Savouring can help students increase their sense of overall well-being and increase their ability to cope during hard times.</p>
    </div>
  </section>
</template>

<script>
// import Accordion from '../components/Accordion.vue'
// import SidebarMenu from '../components/SidebarMenu.vue'
// import {
//   Hooper,
//   Slide,
//   Navigation as HooperNavigation,
//   Pagination as HooperPagination
// } from 'hooper'
// import 'hooper/dist/hooper.css'

export default {
  name: '02',
  components: {
    // SidebarMenu,
    // Accordion,
    // Hooper,
    // Slide,
    // HooperNavigation,
    // HooperPagination
  },
  data () {
    return {
      publicPath: process.env.BASE_URL
    }
  },

  mounted () {
  }
}
</script>

<style>
</style>
